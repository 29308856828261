input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #ffffff;
    padding: 3px 15px;
    border-radius: 10px;
    color: #000000;
    cursor: pointer;
    font-weight: 600;
}

.expenseBtn_1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form1 {
    margin-bottom: 20px;
}

.space_input {
    display: flex;
    justify-content: space-between;
    width: 333px;
}

.dash-container3 {
    background: rgba(100, 228, 142, 0.80);
    height: 60px;
}

.header-top3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.header-top3 .dash-header1 {
    margin-left: 10px;
}

.header-top3 .dash-header1 h5 {
    padding: 0 20px;
    color: #000;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
}

.header-top3 .dash-header2 img {
    padding: 0 10px;
    cursor: pointer;
}

.header-top3 .dash-header2 img:hover {
    transform: scale(1.05, 1.05);
}


/* Row 2 */
.expense {
    margin-top: 30px;
    margin-right: 50px;
    margin-left: 50px;
    /* width: 1098px; */
    /* height: 444px; */
    flex-shrink: 0;
    border-radius: 50px;
    background: #FFF;

}

.expense-header {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.input-menu_expense {
    justify-content: start;
}

.expense-header .btn1 {
    border-radius: 10px;
    background: #64E48E;
    ;
    width: 127px;
    height: 31px;
    border: none;
    font-weight: 500;
}

.expense-header .btn2 {
    width: 100px;
    height: 31px;
    border: none;
    border-radius: 10px;
    border: 1px solid #73C31D;
    background: #FFF;
    font-weight: 500;
}


.expeseBtn1 {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
}

/* form section */
.signup-form {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
    padding-bottom: 20px;
}

@media screen and (min-width: 886px) {}

@media screen and (min-width: 900px) {
    .signup-form .form1 {
        margin: 10px 0;
        padding: 10px;
        max-width: 50%;
    }

    .signup-form .form2 {
        margin: 10px 0;
        padding: 10px;
        max-width: 50%;
    }
}

.signup-form label {
    color: #000;
    font-family: Inter;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 15px;
}

.signup-form input {
    width: 223px;
    height: 29px;
    padding: 18px;
    border: 1px solid gray;
    border-radius: 5px;
    border-color: gray;
    height: 40px;
}

.signup-form input {
    /* border-bottom:2px solid #04542C !important; */
    outline: none;
}

.signup-form .expense-date:focus {
    border-bottom: 2px solid #04542C !important;
}

.signup-form input[name="amount"] {
    margin-left: 32px;
}

.signup-form input[name="amount"]:focus {
    border-bottom: 2px solid #04542c !important;
}

.signup-form input[name="description"] {
    margin-left: 3px;
    height: 100px;
}

.expense-dropdown1 {
    display: flex;
    align-items: center;
}

.expense-dropdown1 .drop-area1 {
    width: 223px;
    border: 1px solid gray;
}

.expense-dropdown1 .drop-area1:focus {
    box-shadow: none;
    border-bottom: 2px solid #04542C !important;
}

.expense-dropdown2 {
    display: flex;
    align-items: center;
}

.expense-dropdown2 .drop-area2 {
    width: 250px;
    margin-left: 19px;
    border: 1px solid gray;
}

.expense-dropdown2 .drop-area2:focus {
    border-bottom: 2px solid #04542C !important;
    outline: none;
    box-shadow: none;
}

.signup-form .text-area {
    width: 100%;
    height: 80px;
    resize: none;
    border: 1px solid lightgray;
    border-radius: 5px;
    max-width: 223px;
}

.signup-form .text-area:focus {
    border-bottom: 2px solid #04542C !important;
    outline: none;
}

.expense-dropdown3 {
    display: flex;
    align-items: center;
    cursor: pointer;
}


.expense-dropdown3 .drop-area3 {
    width: 245px;
    border: 1px solid gray;
}

.expense-dropdown3 .drop-area3:focus {
    border-bottom: 2px solid #04542C !important;
    box-shadow: none;
}

.expense-dropdown4 {
    display: flex;
    align-items: center;
}

.expense-dropdown4 .drop-area4 {
    width: 223px;
    border: 1px solid gray;
}

.expense-dropdown4 .drop-area4:focus {
    border-bottom: 2px solid #04542C !important;
    box-shadow: none;
}


.expense-dropdown5 {
    display: flex;
    align-items: center;
}

.expense-dropdown5 .drop-area5 {
    width: 223px;
    border: 1px solid gray;
}

.expense-dropdown5 .drop-area5:focus {
    border-bottom: 2px solid #04542C !important;
    box-shadow: none;
}





/* btn */

.expenseBtn .expeseBtn1 .btn1 {
    width: 160px;
    height: 29px;
    background: #04542C;
    border: none;
    color: #FFF;
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    font-size: 12px;
}

.expenseBtn .expeseBtn1 .btn2 {
    width: 160px;
    /* height: 29px; */
    background: #04542C;
    border: none;
    color: #FFF;
    margin-left: 40px;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    padding: 12px;
}

.expense .expeseBtn2 {
    margin-top: 40px;
    margin-left: 15px;
}

.expense .expeseBtn2 .btn3 {
    width: 140px;
    height: 31px;
    flex-shrink: 0;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    color: #000;
    background: #ECEBEB;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
}

.expense .expeseBtn2 .btn4 {
    width: 140px;
    height: 31px;
    margin-left: 50px;
    color: #000;
    font-weight: 600;
    flex-shrink: 0;
    border-radius: 10px;
    border: none;
    background: #ECEBEB;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
}

.expense .btn5 {
    width: 179px;
    height: 31px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #64E48E;
    border: none;
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 600;
    float: right;
    margin-top: 30px;
}

.fileSelaction {
    width: 223px !important;
    /* height: 64px; */
    border-radius: 10px;
    height: auto !important;
    padding: 7px !important;
    border: 1px solid gray;
    border-radius: 10px !important;
    border-color: gray;
    background: #04542c;
    font-size: 14px;
    /* font-family: Inter; */
    color: white;
}


/* Row 3 */

.search {
    margin: 35px 50px 0px 50px;
    /* width: 1200px; */
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #04542C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;

}

.searchbar {
    width: 180px;
    height: 39px;
    flex-shrink: 0;
    border: 1px solid #73C31D;
    background: #FFF;
    margin-left: 20px;
    display: flex;
    /* align-items: center; */
}

.search .searchbar p {
    padding-top: 8px;
    padding-left: 10px;
    vertical-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.search .searchbar svg {
    margin-top: 5px;
    margin-left: 30px;

}

.searchbar-box {
    margin-left: 15px;
    width: 54px;
    height: 39px;
    flex-shrink: 0;
    border: 1px solid #73C31D;
    background: #FFF;
    font-size: 15px;
    padding-left: 10px;
    padding-top: 5px;
    font-family: 'Inter', sans-serif;
}

/* Row 4 */

.myexpense-table {
    margin: 50px;
    margin-top: 30px;
}

.worksheet {
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 100px;
    /* width: 1098px; */
    height: 300px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.worksheet ul {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* width: 1106px; */
    height: 50px;
    flex-shrink: 0;
    border-radius: 30px 30px 0px 0px;
    background: #04542C;
}

.worksheet ul li {
    list-style-type: none;
    color: #FFF;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    padding: 0px 22px;
}

.worksheet .border1,
.border2,
.border3 {
    /* width: 1150px; */
    margin-top: 60px;
    margin-left: 25px;
    margin-right: 25px;
    height: 2px;
    background: #C1C1C1;
}


@media screen and (max-width: 1100px) {
    .expense .btn5 {
        /* margin-left: 15px; */
        width: 179px;
        float: left;
    }

    .spm {
        display: none;
    }

    .worksheet {
        margin-right: 50px;
        margin-left: 50px;

    }

    .worksheet ul {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .worksheet ul li {
        list-style-type: none;
        color: #FFF;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        padding: 0px 5px;
    }

}

@media screen and (max-width: 1046px) {
    .signup-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0px !important;
        padding: 0px 10px !important;
    }

    .worksheet ul li {
        list-style-type: none;
        color: #FFF;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        padding: 0px 3px;
    }
}

@media screen and (max-width: 576px) {
    .space_input {
        width: 317px;
    }

    .expense-dropdown3 .drop-area3 {
        width: 217px;
    }

    .expense-dropdown4 .drop-area4 {
        margin-left: 0px;

    }

    .expense-dropdown5 .drop-area5 {
        margin-left: 0;
    }

    .signup-form .text-area {
        width: 200px;
    }

    .expense {
        margin-top: 30px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .signup-form label {
        font-size: 12px;
        margin-right: 8px;
        margin-left: 0;
        padding-left: 0;
    }

    .signup-form input {
        width: 200px;
    }

    .expense-dropdown1 .drop-area1 {
        width: 200px;
    }

    .expense-dropdown2 .drop-area2 {
        width: 200px;
    }

    .expense-dropdown3 .drop-area3 {
        width: 217px;
    }

    .expense-dropdown4 .drop-area4 {
        width: 200px;
    }

    .expense-dropdown5 .drop-area5 {
        width: 200px;
    }

    .search {
        margin-top: 30px;
        margin-left: 8px;
        margin-right: 8px;

        column-gap: 15px;

    }

    .expenseBtn .expeseBtn1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .expenseBtn .expeseBtn1 .btn2 {
        margin-left: 0px;
        margin-top: 10px;
    }

    .expense .expeseBtn2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .expense .expeseBtn2 .btn4 {
        margin-left: 0px;
        margin-top: 10px;
    }

    .expense .btn5 {
        /* margin-top: 20px;
        margin-right: 0px;
        margin-left: 40px; */

    }

    .myexpense-table {
        margin: 8px;
    }
}
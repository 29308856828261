.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    max-width: 750px !important;
}
.css-uhb5lp{
    max-width: 750px !important;
}
.gridItem{    
    border-radius: 10px;
    width: 50%;
    padding: 5px 10px;
}
.accessibility_lable{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0px !important;
    padding: 0px 46px;
}
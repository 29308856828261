.App{
    background: #f1f1f1;
}
.content_right_dashboard_2{
  width: calc(100vw - 247px) !important;
  margin-left: 230px !important;
}
.content_right_dashboard_3{
  width: calc(100vw - 235px) !important;
  margin-left: 218px !important;
  padding: 0 !important;
}

.input_tag {
  border: 1px solid #c4c4c4 !important;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 10px !important;
  /* box-shadow: inset 0 3px 6px rgba(0,0,0,0.1) !important; */
}

input {
    border: 1px solid #c4c4c4 !important;
    border-radius: 5px;
    background-color: #fff;
    padding: 3px 10px !important;
    /* box-shadow: inset 0 3px 6px rgba(0,0,0,0.1); */
  }

  select {
    border: 1px solid #c4c4c4 !important;
    border-radius: 5px;
    background-color: #fff;
    padding: 3px 5px;
    /* box-shadow: inset 0 3px 6px rgba(0,0,0,0.1); */
  }

/* .dialog-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} */

.timer-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .dialog-main .timer {
    margin-top: 10px;
    margin-left: 10px;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    width: 100px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #04542c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 15px;
    cursor: context-menu;
  }
  
  .timer-button .btn1 {
    border: none;
    color:  #000;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 10px;
    background: rgb(100, 228, 142);
    padding: 5px 20px;
  }
  
  .timer-button .btn2 {
    border: none;
    color: #fff;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 10px;
    border-radius: 10px;
    background: #04542c;
    padding: 5px 20px;
  }
  
  .dialog-main p {
    margin-top: 10px;
    text-align: center;
    color: #c1c1c1;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  
  .border-around {
      margin: auto;
      width: 90%;
      border-radius: 10px;
      padding: 5px;
      border: 1px solid rgba(0, 0, 0, 0.25); 
      padding-bottom: 25px;
  }
  
  .form-time1 {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .customer {
    /* border: 1px solid rgba(0, 0, 0, 0.25); */
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .customer label {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .task {
    /* border: 1px solid rgba(0, 0, 0, 0.25); */
    padding: 10px 20px;
    border-radius: 10px;
  }
  
  .task label {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .textarea {
    width: 100%;
    margin: auto;
  }
  
  .textarea textarea {
    width: 100%;
    resize: none;
    border-radius: 10px;
    height: 75px;
    margin: auto;
    border: 1px solid #c9c9c9;
  }

  .textarea textarea:focus {
    border-bottom: 2px solid #04542c;
    outline: none;
  }
  
  .strong {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .strong strong {
    border: none;
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 10px;
    border-radius: 10px;
    background: #04542c;
    padding: 8px 25px;
    cursor: pointer;
  }
  
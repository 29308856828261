.disconnec_parent{
min-height: 100vh;
padding: 0px 20px;
}
.disconnect_logo_parent{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    flex-direction: column;
}

.disconnect_content{
    /* margin-top: 50px; */
    display: flex;
    justify-content: center;
}
.disconnect_content div {
    max-width: 600px;
}

.disconnect_content div .disconnect_text{
    font-size: 30px;
    text-align: center;
}

.disconnect_content div div{
    padding: 17px;
    background-color: white;
    border-radius: 10px;
    font-size: 17px;
}


/* PrimeFeatures */
.PrimeFeatures {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-image: url(../img/HeroImg.png);
  background-size: cover;
  width: 100%;
  margin-top: -100px;
  padding-top: 130px;
  padding-bottom: 50px;
  margin-bottom: 100px;
  padding-left: 15px;
  padding-right: 15px;
  gap: 20px;
  min-height: 95vh;
}

.PrimeFeaturesLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bubbleImg1 {
  position: absolute;
  bottom: -80px;
  right: -50px;
  width: 80px;
}

.bubbleImg2 {
  position: absolute;
  left: 0;
  bottom: -85px;
  width: 80px;
}

.bubbleImg3 {
  position: absolute;
  right: 0;
  bottom: -85px;
  width: 80px;
}

.PrimeFeaturesLeft .PrimeFeaturesLeftImg {
  width: 100%;
  height: 400px;
}

/* PrimeFeaturesRight */
.PrimeFeaturesRight h1 {
  font-size: 50px;
  color: #04542c;
  text-align: end;
}

.PrimeFeaturesRight p {
  font-size: 18px;
  font-weight: 400;
  color: black;
  text-align: end;
  max-width: 600px;
}

.PrimeFeaturesRightBtn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.PrimeFeaturesRightBtn button {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  border: none;
  color: #fff;
  text-align: center;
  font-family: League Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 30px;
  background: #04542c;
  transition: transform 0.3s;
}

.PrimeFeaturesRightBtn button:hover {
  border: 2px solid #04542c;
  background: white;
  color: #04542c;
}

/* PrecisionTiming */
.PrecisionTiming {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.PrecisionTiming h1 {
  font-size: 35px;
  text-align: center;
  color: #04542c;
}

.PrecisionTiming p {
  font-family: "League Spartan", sans-serif;
  text-align: center;
  width: 79%;
  font-size: 18px;
  font-weight: 300 !important;
}

/* para */
.timesheetPara {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: end !important;
}

/* .report-container-main */
.report-container-main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  gap: 20px;
  margin: 150px 0;
}

.report-container-main .report-left h1 {
  max-width: 600px;
  color: #73c31d;
  font-family: League Spartan;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  text-align: end;
}

.report-container-main .report-left p {
  margin-top: 10px;
  max-width: 600px;
  color: #595959;
  font-family: League Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  text-align: end;
}

.PrimeFeaturesRightBtn button {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  border: none;
  color: #fff;
  text-align: center;
  font-family: League Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 30px;
  background: #04542c;
  transition: transform 0.3s;
}

.PrimeFeaturesRightBtn button:hover {
  border: 2px solid #04542c;
  background: white;
  color: #04542c;
}

/* report-right */
.report-container-main .report-right {
  position: relative;
}
.report-container-main .report-right .report-right-img {
  width: 100%;
  height: 400px;
}

@media screen and (max-width: 1100px) {
  .PrimeFeatures {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 60px;
  }

  .PrimeFeaturesRight {
    padding-top: 20px;
  }

  .PrimeFeaturesRight h1 {
    font-size: 35px;
    text-align: center;
  }

  .PrimeFeaturesRight p {
    text-align: center;
  }

  .PrimeFeaturesRightBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .PrecisionTiming h1 {
    font-size: 25px;
    text-align: center;
  }

  .PrecisionTiming p {
    font-size: 15px;
    text-align: justify;
  }

  .report-container-main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  .report-container-main .report-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
  }
  .report-container-main .report-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .report-container-main .report-left h1 {
    text-align: center;
  }

  .report-container-main .report-left p {
    text-align: center;
  }
  
  .bubbleImg1 {
    display: none;
  }

  .bubbleImg2 {
    display: none;
  }

  .bubbleImg3 {
    display: none;
  }

}

@media screen and (max-width: 600px) {
  .PrimeFeaturesRight p {
    text-align: justify;
    font-size: 15px;
  }

  .report-container-main {
    margin: 50px 0;
  }
  .report-container-main .report-left h1 {
    text-align: center;
    font-size: 20px;
    line-height: 25px;
  }

  .report-container-main .report-left p {
    text-align: justify;
    /* font-size: px; */
  }
}

/* mileage */
.mileage-container-main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  gap: 20px;
  margin: 100px 0;
}

.mileage-container-main .mileage-left h1 {
  max-width: 620px;
  color: #73c31d;
  font-family: League Spartan;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.mileage-container-main .mileage-left p {
  margin-top: 10px;
  max-width: 600px;
  color: #595959;
  font-family: League Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
}

.mileage-container-main .mileage-left button {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  border: none;
  color: #fff;
  text-align: center;
  font-family: League Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 30px;
  background: #04542c;
  transition: transform 0.3s;
}

.mileage-container-main .mileage-left button:hover {
  border: 2px solid #04542c;
  background: white;
  color: #04542c;
}

.featButton {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  border: none;
  color: #fff;
  text-align: center;
  font-family: League Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 30px;
  background: #04542c;
  transition: transform 0.3s;
}

.featButton:hover {
  border: 2px solid #04542c;
  background: white;
  color: #04542c;
}

/* report-right */
.mileage-container-main .mileage-right {
  position: relative;
}
.mileage-container-main .mileage-right .mileage-right-img {
  width: 100%;
  height: 400px;
}

@media screen and (max-width: 1100px) {
  .mileage-container-main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap-reverse;
  }

  .mileage-container-main .mileage-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
  }

  .mileage-container-main .mileage-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .mileage-container-main .mileage-left h1 {
    text-align: center;
  }

  .mileage-container-main .mileage-left p {
    text-align: center;
  }

}

@media screen and (max-width: 600px) {
  .mileage-container-main {
    margin: 50px 0;
  }
  .mileage-container-main .mileage-left h1 {
    text-align: center;
    font-size: 20px;
    line-height: 25px;
  }

  .mileage-container-main .mileage-left p {
    text-align: justify;
    /* font-size: px; */
  }

  /* report-right */
.mileage-container-main .mileage-right .mileage-right-img{
  width: 100%;
  height: auto;
}

/* report-right */
.report-container-main .report-right .report-right-img {
  width: 100%;
  height: auto;
}

.PrimeFeaturesLeft .PrimeFeaturesLeftImg {
  width: 100%;
  height: auto;
}

}

.hero3-container {
    margin-top: 60px;
    margin-bottom: 60px;
}

/* hero3-left */
.hero3-left img{
 height: 350px;
 width: 90%;
}

/* hero3 right */
.hero3-right {
    padding-left: 35px;
}

.hero3-right h1 {
    font-size: 36px;
    max-width: 700px;
    font-family: League Spartan;
    color: #73c31d;
}

.hero3-right p {
    margin-top: 15px;
    max-width: 700px;
    font-size: 17px;
    font-family: League Spartan;
    color: gray;
}

.hero3-right .text-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    margin-top: 20px;
}

.hero3-right .text-img-container .text-img-left h6 {
    font-size: 15px;
    margin-top: 10px;
}

.hero3-right .text-img-container .text-img-left p{
    font-size: 15px;
    max-width: 380px;
    font-family: League Spartan;
}

.hero3-right .text-img-container .text-img-right h6 {
    font-size: 15px;
    margin-top: 10px;
}

.hero3-right .text-img-container .text-img-right p{
    font-size: 15px;
    max-width: 380px;
    font-family: League Spartan;
}

.hero3-container .text-img-container .iconImg  {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

@media screen and (max-width: 992px) {
    .hero3-left {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hero3-right h1 {
        margin-top: 30px;
    }
}

@media screen and (max-width: 576px) {
    .hero3-right h1 {
        font-size: 25px;
    }

    .hero3-right .text-img-container {
        column-gap: 20px;
    }

    .hero3-right {
        padding-left: 0px;
    }

    .hero3-left img{
        height: auto;
        width: 100%;
       }
}
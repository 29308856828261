.full-screen-calendar {
    /* margin-top: 80px;
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center; */
    padding: 0 50px;  
    padding-bottom: 100px;  
    overflow-x: auto;
  }

.react-calendar{
    /* height: 100%; */
    width: 100%;
    min-width: 340px;
    padding: 50px;
    background-color: #fff;
    border-radius: 50px;
    flex-shrink: 0;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

}

.react-calendar__tile{
height: 100px;
border: 1px solid rgb(143, 143, 143) !important;
border-radius: 7px;
}

.react-calendar__month-view__weekdays{
    margin-top: 20px;
}

.react-calendar__navigation {
    background-color: #04542C;
    color: #fff !important;
    border-radius: 50px;
}
.react-calendar__tile--active{
    background-color: #04542C;
}
.react-calendar button{
    font-weight: 600;
}

.react-calendar__month-view__weekdays {
    background: rgba(100, 228, 142, 0.80);
    color: #000;
    margin-bottom: 20px;
    border-radius: 50px;
}
.react-calendar__tile--now{
    background: rgba(100, 228, 142, 0.80);
}

abbr[title] { 
    cursor: pointer;
    font-family: Inter;
}

.react-calendar__navigation__label {
   color: #fff;
   font-family: Inter;
}

.react-calendar__navigation__label:hover {
   color: #000;
   font-weight: 600;
}



.react-calendar__navigation__arrow ,.react-calendar__navigation__prev-button {
    color: #fff;
}



@media screen and (max-width: 680px) {

    .full-screen-calendar{
        padding: 17px 16px;
    }
}

@media screen and (max-width: 576px) {
    .react-calendar{
        padding: 20px;
    border-radius: 23px;
    }

    .react-calendar__tile{
        height: 70px;
    }
}

@media screen and (max-width: 450px) {
    .react-calendar__tile{
        height: 50px;
    }
}

@media screen and (max-width: 992px) {   
  .header-left {
      display: none;
  }
}

/* .main-container {
    margin: 50px 100px;
    margin-top: 50px;
    margin-left: 100px;
} */

/* .header-container {
    background: rgba(100, 228, 142, 0.50);   
    border-radius: 50px;
} */

/* background-color */
.background-color {
    background: rgba(100, 228, 142, 0.5);
    border-radius: 50px;
    height: 100%;
    /* padding: 1px; */
  }
  
  /* left sidebar */
  /* .header-left {
    padding-left: 50px;
    padding-right: 50px;
    background: rgba(100, 228, 142, 0.50); 
    border-radius: 50px
  } */
  
  .header-left h1 {
    color: #04542c;
    text-align: center;
    margin-top: 20px;
    font-size: 40px;
    font-weight: 400;
    font-family: "League Spartan", sans-serif;
  }
  
  .img-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
  }
  
  .img-container .img {
    border: 2px solid #30b05a;
    background-color: #fff;
    width: 135px;
    height: 155px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
  }
  
  /* .img-container .img img {
      width: 150px;
  } */
  
  .img-container .img p {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    font-family: "Roboto Condensed", sans-serif;
  }
  
  .header-left .header-left-para {
    color: #04542c;
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    padding-top: 20px;
    font-family: 'Inter';

  }
  
  /* media query */
  @media screen and (max-width: 1500px) {
    .header-left .header-left-para {
     padding-left: 30px;
     padding-right: 30px;
  
    }
      
  }
  
  
  @media screen and (max-width: 1400px) {
    .img-container .img {
      width: 116px;
      height: 145px;
    }
  
    .img-container .img p {
      font-size: 15px;
    }
  
    .header-left h1 {
      font-size: 34px;
      margin: 0;
      /* padding-bottom: 50px; */
      padding-top: 50px;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .img-container .img {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 3px;
      width: 90px;
      height: 130px;
    }
  
    .img-container .img p {
      font-size: 13px;
      margin-top: 0;
    }
  
    .header-left h1 {
      font-size: 30px;
      margin: 0;
      /* padding-bottom: 50px; */
      /* padding-top: 50px; */
    }
  }

  @media screen and (max-width: 992px) {
    .header-left {
      display: none;
    }
  }
  
  @media screen and (max-width: 1050px) {
      .header-left {
        padding-left: 0px;
        padding-right: 0px;
      }
  }
  
  
    @media screen and (max-width: 830px) {
      .main-container {
        margin: 0px;
      }
    
      .parent_div {
        padding: 0;
      }
      .img-container .img {
        border: 2px solid #30b05a;
        background-color: #fff;
        width: 105px;
        height: 145px;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
      }
    
      .img-container .img p {
        margin-top: 10px;
        font-size: 15px;
        font-weight: 600;
        color: #000;
      }
    }
  
  @media screen and (max-width: 600px) {
    .img-container {
      display: none;
    }
  
    .header-left {
      display: none;
    }
  
    .header-left-para {
      display: none;
    }
  
    .header-container {
      background: none;
    }
  
    form {
      padding: 0px 5px;
      padding-top: 20px;
    }
  
    .header-container {
      background: none;
    }
  
    .main-container {
      margin: 5px 10px;
    }
  
    .parent_div {
      padding: 0;
    }
  
    .header-left {
      background: none;
    }
  
    body {
      background: #f1f1f1;
    }
  }
   
  
  @media screen and (max-width: 576px) {
      .main-container {
        margin: 0;
      }
    
      .parent_div {
        padding: 0;
      }
      .img-container .img {
        border: 2px solid #30b05a;
        width: 85px;
        height: 110px;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
      }
    
      .img-container .img img {
        width: 30px;
      }
    
      .header-left h1 {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 336px;
        font-size: 25px;
        margin: 0;
        padding-bottom: 50px;
        padding-top: 50px;
      }
    
      .img-container .img p {
        font-size: 13px;
      }
    
      .header-container {
        margin-bottom: 30px;
      }
  }
    
.main-features h1 {
    margin-top: 70px;
    text-align: center;
    color: #04542C;
    text-align: center;
    font-family: League Spartan;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.feat_img_1 img{
    width: 68px;
}

.features-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 50px;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}

.blogTextContainer:hover .feat-img {
    display: none;
  }
  
  .blogTextContainer:hover .featureBlogImg {
    display: block;
  }

  .featureBlogImg:hover {
    -ms-transform: scale(1.04); /* IE 9 */
    -webkit-transform: scale(1.04); /* Safari 3-8 */
    transform: scale(1.04);
    transition: 0.5s;
  }
  
  /* featBlogImg1 */
  
  .featureBlogImg {
    display: none;
    position: relative;
  }
  
  .featureBlogImg img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 290px;
    cursor: pointer;
    border-radius: 10px; 
  }

.feat_img_1{
    transition: transform .5s;
}
.feat_img_1:hover{
    -ms-transform: scale(1.04); /* IE 9 */
    -webkit-transform: scale(1.04); /* Safari 3-8 */
    transform: scale(1.04); 
}

.feat-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 290px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #30B05A;
    background: #FFF;
    cursor: pointer;
}

.feat-img h1 {
    margin-top: 20px;
    color: #000;
    text-align: center;
    font-family: League Spartan;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.feat-img p {
    margin-top: 20px;
    padding: 5px;
    width: 220px;
    color: #595959;
    text-align: center;
    font-family: League Spartan;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

@media screen and (max-width: 1150px) {
    .features-container {
        display: flex;
        gap: 30px;
        justify-content: center;
        align-items: center;
        margin-top: 70px;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 576px) {
    .feat-img h1 {
        font-size: 18px;        
    }

    .main-features h1 {
        margin-top: 20px;
        font-size: 25px;
        
    }    
}
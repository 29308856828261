.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.video-container {
    width: 80%;
    height: 80%;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    color: white;
    cursor: pointer;
}

ul a {
    text-decoration: none;
}







/* navbar */
body {
    background: #f1f1f1 !important ;
    font-family: Inter !important;
    margin: 0;
}

.error {
    border: 2px solid red !important;
}

.navbar-container-learn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0px;
}

.navbar-container-learn .nav-logo-learn img {
    width: 50%;
}

.nav-menu-learn {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.nav-menu-learn li {
    padding: 5px 20px;
    list-style-type: none;
    margin-left: 10px;
    border-radius: 20px;
    border: 2px solid transparent;
    text-decoration: none;
    color: #04542c;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;

}

.nav-menu-learn li:hover {
    border: 2px solid #04542c;
    /* background: white; */
}

.nav-menu-learn li a {
    text-decoration: none;
    color: #000;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}


.nav-button-learn button {
    border: none;
    margin-left: 10px;
}

/* .new_nav_back{
    background-image: url("./webComponents/images/back.svg") !important;
} */
.nav-button-learn .nav-btn1 {
    color: #04542C;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 30px;
    background: rgba(115, 195, 29, 0.80);
    padding: 12px 30px;
    border: 1px solid rgba(115, 195, 29, 0.80);
}

.nav-button-learn .nav-btn1:hover {
    background: white;
    color: #04542C;
}

.nav-button-learn .nav-btn2 {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex-shrink: 0;
    border-radius: 30px;
    background: #04542C;
    padding: 12px 30px;
    border: 1px solid #04542C;
}

.nav-button-learn .nav-btn2:hover {
    background: white;
    color: #04542C;
}

.hamburger {
    position: absolute;
    top: 16px;
    right: 15px;
    font-size: 28px;
    display: none;
    color: #000;
}

.hamburger_icon {
    margin-top: 21px;
}

@media screen and (max-width: 600px) {
    .nav-button-learn button {
        padding: 6px 15px !important;
        /* margin-bottom: 21px; */

    }
}

@media screen and (max-width: 1300px) {
    .nav-menu-learn li a {
        font-size: 17px;
    }

    .nav-button-learn .nav-btn1 {
        font-size: 18px;
        font-weight: 500;
        border-radius: 20px;
        padding: 12px 20px;
    }

    .nav-button-learn .nav-btn2 {
        font-size: 20px;
        font-weight: 500;
        border-radius: 20px;
        padding: 12px 20px;
    }
}

@media screen and (max-width: 1210px) {
    .nav-menu-learn {
        /* background-color: #3e27db; */
        background: #04542C;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: end;
        height: 38vh;
        width: 100%;
        position: absolute;
        top: 90px;
        right: 0;
        opacity: 0;
        z-index: 999;
        display: none;
        padding: 0;
    }

    .active {
        display: block;
    }

    .nav-menu-learn li {
        padding: 8px 0;
        list-style: none;
        color: #fff;
    }

    .nav-menu-learn li a {
        color: #fff;
        /* color: #2e85a3 */
    }

    .drop a {
        color: #fff;
    }

    .btn {
        color: #fff;
    }

    .hamburger {
        display: block;
    }

    .nav-menu-learn.active {
        opacity: 1;
    }

    ol,
    ul {
        padding-left: 0rem;
    }

    .nav-button-learn {
        margin-right: 30px;
    }
}


@media screen and (max-width: 500px) {
    .nav-menu-learn {
        /* background-color: #3e27db; */
        top: 80px;
        padding-top: 0px;
        display: none;

    }

    .nav-button-learn button {
        margin-bottom: 0;
        margin-top: 21px;
    }

    .hamburger_icon {
        margin: 0;
    }

    .active {
        display: block;
    }

    .nav-button-learn {
        margin-right: 40px;
    }

    .nav-button-learn .nav-btn1 {
        font-size: 15px;
        padding: 8px 15px;
    }

    .nav-button-learn .nav-btn2 {
        font-size: 15px;
        padding: 8px 15px;
    }

    .navbar-container-learn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;

    }

    .navbar-container-learn .nav-logo-learn {
        width: 120px;
    }

    .navbar-container-learn .nav-logo-learn img {
        width: 70%;
        margin-right: 0;
        padding-right: 0;
    }

    .nav-button-learn {
        margin-top: 0px;
    }

    .nav-logo-learn {
        width: 19% !important;
    }

    .navbar-container-learn {
        padding-right: 8px;
        align-items: baseline;
        justify-content: space-between;
    }
}



.heroSection1 {
    padding: 40px 20px;
}

.heroSection1 h1 {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    color: #065430;
    margin-bottom: 20px;
}

.heroSection1 h3 {
    font-family: Inter;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #539105;
    margin-bottom: 30px;
}

.heroSection1 div {
    display: flex;
    justify-content: center;
    margin: 0;
}

.heroSection1 p {
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 25px;
}

.heroSection1 img {
    width: 60%;
}


.hero2 {
    padding: 50px 30px 0px 30px;
}

.hero2 .hero_head {
    display: flex;
    justify-content: center;
}

.hero2 .hero_head img {
    width: 280px;
}


.hero_section_3 {
    padding: 53px 10px 100px 10px;

}

.hero_section_3 h1 {
    font-family: Inter;
    font-size: 38.03px;
    font-weight: 700;
    line-height: 38.45px;
    text-align: center;
    color: #065430;
    margin-bottom: 82px;
}

.hero_section_3 h1 span {
    color: #065430;
}

.hero_section_3_left_col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero_section_3_right_col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero_section_3_right_col img {
    max-width: 80%;
    border-radius: 5px;
}

.hero_section_3_left_col h2 {
    font-family: Inter;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: #065430;
    text-decoration: underline;
}

.hero_section_3_left_col ul {
    color: #065430;
    list-style: none;
    padding: 0;
}

.hero_section_3_left_col ul li {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
    margin-top: 20px;

}

.hero_section_3_left_col .try {
    width: 171px;
    height: 43.97px;
    background-color: #065430;
    font-family: Inter;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 26px;
    margin-top: 30px;
}

.try_for_small {
    width: 171px;
    height: 43.97px;
    background-color: #065430;
    font-family: Inter;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 26px;
}

.hero_section_3_left_col .try:hover {
    color: #065430;
    background-color: white;
    border: 2px solid #065430;
}

.try_for_small:hover {
    color: #065430;
    background-color: white;
    border: 2px solid #065430;
}


.try_for_small {
    margin-top: 30px;
    width: 171px;
    height: 43.97px;
    background-color: #065430;
    font-family: Inter;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 26px;
    display: none;
}

.hero_section_4_left_col {
    justify-content: start;
}

.hero_section_4_5 {
    padding-top: 30px !important;
}

.hero_section_4_row {
    flex-direction: row-reverse;
}


.hero3 {
    background: #04542C;
    padding: 46px 20px 20px 20px;
}

.waveback1 img {
    max-width: 100%;
    margin-bottom: -16px;
    width: 100%;
}

.waveback2 {
    margin-top: -2px;
}

.waveback2 img {
    max-width: 100%;
    margin-top: -20px;
    width: 100%;
}

.hero3_row {
    justify-content: center;
}

.hero3_col {
    padding: 0;
    display: flex;
    justify-content: center;
}

.hero3_col img {
    max-width: 80%;
    margin-bottom: 33px;
}


.hero3 .hero_head {
    display: flex;
    justify-content: center;
}

.hero3 .hero_head img {
    width: 280px;
    margin-bottom: 40px;
}

.hero4 {
    margin: 90px 69px;
    border: 3px solid #04542C;
    border-style: dashed;
    padding: 20px 40px;
    border-radius: 30px;
}

.hero4 .content {
    border: 3px solid #04542C;
    border-radius: 30px;
    padding: 20px;
}

.hero4_left p {
    font-weight: 500;
    font-size: 27px;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.hero4_left p img {
    margin-right: 15px;
}

.hero4 .hero_head {
    display: flex;
    justify-content: center;
}

.hero4 .hero_head img {
    width: 250px;
    margin: 30px 0px;
}

.hero4_right img{
    margin: 25px 0px;
    width: 78%;
}

.hero5 h1 {
    font-size: 34px;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 37px;
    color: #04542C;
}

.content_box {
    background: #04542C;
    border-radius: 57px;
    padding: 30px;
    padding-top: 70px;
}

.hero5_left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;
    border-right: 2px solid rgb(177, 177, 177);
}

.hero5_left h3 {
    font-size: 59px;
    color: white;
}

.hero5_left p {
    font-size: 38px;
    color: white;
    font-weight: 600;
}

.hero5_right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;
    margin-top: 20px;
}

.hero5_right div {
    display: flex;
    align-items: center;
}

.hero5_right div img {
    width: 50px;
    margin-right: 20px;
}

.hero5_right div p {
    font-size: 27px;
    font-weight: 500;
    color: white;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 20px 0px;

}

.button button {
    font-size: 30px;
    padding: 5px 20px;
    color: white;
    background: transparent;
    border: 2px solid white;
    border-radius: 20px;
}

footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background: #64E48E;
    margin-top: 52px;
}

.footer_left {
    display: flex;
    flex-direction: column;
    gap: 37px;
}

.footer_left img {
    width: 115px;
}

.footer_left p {
    font-size: 24px;
    font-weight: 600;
    color: #04542C;
}

.footer_naviagting {
    font-size: 24px;
    font-weight: 600;
    color: #04542C;
}

.footer_naviagting a{
    font-size: 24px;
    font-weight: 600;
    color: #04542C;
    text-decoration: none;
}

.subscribe {
    background: white;
    border-radius: 20px;
}

.subscribe input {
    border: none !important;
    font-size: 20px;
    border-radius: 14px;
    padding-left: 10px;
}

.subscribe input:focus {
    outline: none;
}

.subscribe button {
    border: 1px solid #04542c;
    background: #04542c;
    color: white;
    border-radius: 20px;
    font-size: 20px;
    padding: 5px 12px;
}

.hero7 {
    margin: 85px 96px;
    padding: 51px;
    border-radius: 148px 10px 149px 10px;
    background: #04542c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.hourglassTop {
    position: absolute;
    top: 20px;
    right: 72px;
}

.hourglassButtom {
    position: absolute;
    bottom: 20px;
    left: 72px;
}

.hero7 h1 {
    text-align: center;
    color: white;
    font-size: 28px;
}

.hero7 form {
    padding-top: 27px;
    width: 53%;
}

.hero7 div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.hero7 div p {
    color: white;
    font-size: 20px;
}

.hero7 div input {
    padding: 0px 28px;
    border-radius: 20px;
    width: 368px;
    border: none;
}

.book_demo {
    display: flex;
    justify-content: center !important;
    margin-top: 36px;
}

.book_demo button {
    color: white;
    background: transparent;
    border: 2px solid white;
    border-radius: 20px;
    padding: 5px 20px;

}

#success {
    display: none;
    text-align: center;
    color: white;
    margin: 0;
    font-size: 14px;
}


.hero6_col{
    display: flex;
    justify-content: center;
}



.hero6{
    margin: 30px 0px;
}





.testimonial-card {
    background-color: #f9f9f9;
    border: 2px solid #006400;
    border-radius: 15px;
    padding: 20px;
    max-width: 300px;
    text-align: center;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 60px; /* Adjusted for profile picture overlap */
}

.profile-pic {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    border: 4px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
    width: 80px;
    height: 80px;
    background-color: #fff; /* Adding background color for better appearance */
}

.profile-pic img {
    width: 100%;
    height: auto;
}

.content {
}

.content h2 {
    font-size: 1.2em;
    margin: 10px 0 5px;
    font-weight: bold;
}

.content .title {
    color: #777777;
    font-size: 0.9em;
    margin: 0 0 20px;
}

.content .testimonial {
    font-size: 0.9em;
    color: #333333;
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
}

@media screen and (max-width: 1300px) {
    .hero7 form {
        width: 60%;
    }
}


/* below 1188 */
@media screen and (max-width: 1188px) {
    .hero_section_3_left_col h2 {
        font-size: 25px;
    }

    .hero_section_3_left_col ul li {
        font-size: 16px;
    }


    .hero_section_3_right_col img {
        max-width: 90%;
    }
    .hero7 form {
        width: 70%;
    }
    .hourglassTop {
        right: 33px;
    }
    .hourglassButtom {
        right: 33px;
    }

}

@media screen and (max-width: 1250px) {
    .hero4_right {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hero4_right img {
        width: 80%;
    }

    .hero4_left p {
        font-size: 26px;
    }
}

@media screen and (max-width: 1044px) {
    .hero7 form {
        width: 80%;
    }
    .hourglassTop {
        right: 33px;
        width: 100px;
    }
    .hourglassButtom {
        right: 33px;
        width: 100px;
    }

}

@media screen and (max-width: 992px) {
    .heroSection1 h1 {
        font-size: 22px;
        font-weight: 600;
    }

    .heroSection1 h3 {
        font-size: 18px;
        color: #539105;
    }

    .heroSection1 img {
        width: 70%;
    }

    .hero_section_3 {
        padding: 53px 5px 50px 5px;
    }

    .hero_section_3 h1 {
        font-size: 32px;
        margin-bottom: 50px;
    }

    .hero_section_3_right_col img {
        max-width: 70%;
        margin-top: 30px;
    }

    .hero_section_4_left_col {
        justify-content: center;
    }

    .hero_section_3_left_col h2 {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
    }

    .hero_section_3_left_col ul li {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }

    .hero_section_3_left_col .try {
        display: none;
    }

    .try_for_small_parent {
        display: flex;
        justify-content: center;
    }

    .try_for_small {
        display: block;
    }

    .hero4 {
        margin: 92px 35px;
        padding: 15px 30px;
    }

    .hero4_left p {
        font-size: 24px;
        font-weight: 500;
    }

    .hero4_right {
        display: flex;
        justify-content: center;
        margin: 35px 0px;
    }

    .hero4_right img {
        width: 50%;
    }

    .hero4_row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hero5 h1 {
        font-size: 28px;
    }

    .hero5_left h3 {
        text-align: center;
    }

    .hero5_left p {
        text-align: center;
    }

    .hero5_left {
        border: none;
    }

    .hero5_right div {
        justify-content: center;
    }

    footer {
        flex-direction: column;
    }

    .footer_left {
        align-items: center;
    }

    .footer_left p {
        text-align: center;
    }

    .footer_naviagting p {
        text-align: center;
    }

    .hero7 form {
        width: 90%;
    }
    .hourglassTop {
        right: 10px;
        width: 100px;
    }
    .hourglassButtom {
        right: 10px;
        width: 100px;
        bottom: 55px;
    }
}


@media screen and (max-width: 876px) {
   
    .hero7 form {
        width: 100%;
    }
    .hourglassTop {
        right: 10px;
        width: 50px;
    }
    .hourglassButtom {
        right: 10px;
        width: 50px;
    }

    .hero7{
        margin: 58px 33px;
    }
}


@media screen and (max-width: 680px) {
    .heroSection1 h3 {
        margin-bottom: 20px;
    }

    .heroSection1 img {
        width: 80%;
    }



    .hero_section_3 h1 {
        font-size: 28px;
        margin-bottom: 35px;
    }

    .hero_section_3_right_col img {
        max-width: 90%;
        margin-top: 15px;
    }

    .hero_section_3_left_col h2 {
        font-size: 18px;
        font-weight: 600;
    }

    .hero_section_3_left_col ul li {
        font-size: 14px;
        font-weight: 400;
        margin-top: 10px;
    }

    .try_for_small {
        display: block;
    }

    .hero4 {
        margin: 50px 10px;
        padding: 10px 15px;
    }

    .hero4_left p {
        font-size: 15px;
        font-weight: 500;
    }

    .hero4_right {
        margin: 35px 0px;
    }

    .hero4_right img {
        width: 50%;
    }

    .hero4 .hero_head img {
        width: 200px;
    }

    .hero4_right img {
        width: 65%;
    }

    .hero4 .content {
        padding: 10px;
    }


    .hero5 h1 {
        font-size: 24px;
        padding: 0px 15px;
    }

    .content_box {
        padding: 40px 10px;
        padding-top: 50px;
    }

    .hero5_left h3 {
        font-size: 40px;
    }

    .hero5_left p {
        font-size: 30px;
    }

    .hero5_right div img {
        margin-right: 10px;
    }

    .hero5_right div p {
        font-size: 20px;
        margin: 0;
    }

    .hero5_right div img {
        width: 30px;
    }

    .button {
        margin-top: 30px;
    }

    .button button {
        font-size: 20px;
    }

    .footer_left p {
        font-size: 20px;
    }

    .footer_naviagting p {
        font-size: 20px;
    }

    .subscribe {
        max-width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .subscribe input {
        width: 55%;
    }

    .hero7{
        margin: 58px 10px;
        border-radius: 103px 10px 103px 10px;
        padding: 51px 31px;

    }

    .hero7 form {
        width: 100%;
    }
    .hourglassTop {
        right: 10px;
    }
    .hourglassButtom {
        right: 10px;
    }

    .hero7 div input{
        width: 280px;
    }

}

@media screen and (max-width: 524px) {
    .hero7 form {
        width: 100%;
    }
    .hourglassTop {
        right: 10px;
    }
    .hourglassButtom {
        right: 10px;
    }

    .hero7 div input{
        width: 220px;
    }
    .hero7 h1{
        font-size: 24px;
    }

    .hero7 div p{
        font-size: 16px;
    }
}

@media screen and (max-width: 441px) {
    .hero7{
        padding: 51px 11px;
        border-radius: 68px 10px 68px 10px;

    }
    .hero7 div input {
        width: 194px;
    }

    .hourglassTop {
    top: 77px;
    }
    .hourglassButtom {
    bottom: 85px;
    left: 20px;

    }

}

@media screen and (max-width: 400px) {
    .heroSection1 h1 {
        font-size: 18px;
        font-weight: 600;
    }

    .heroSection1 h3 {
        font-size: 16px;
        color: #539105;
    }

    .heroSection1 img {
        width: 90%;
    }
}
.team-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px;
    margin-top: 70px;
}

.team-text .icon-img {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.team-text h5 {
    width: 600px;
    color: #73C31D;
    font-family: League Spartan;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.team-text .icon-img h6 {
    color: #000;
    text-align: center;
    font-family: League Spartan;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.team-text p {
    margin-top: 10px;
    width: 600px;
    color: #595959;
    font-family: League Spartan;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px; 
}

.team-text button {
    margin-top: 20px;
    border: none;
    color: #FFF;
    font-family: League Spartan;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* width: 150px; */
    padding: 2px 20px;
    /* height: 40px; */
    flex-shrink: 0;
    border-radius: 23px;
    background: #04542C;
    border: 2px solid #04542C;
}
.team-text button:hover{
        background: white;
        color:#04542C ;
   
}
/* time-img */
.team-img {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    /* width: 550px; */
}
/* 
.time-img img {
    width: 100%;
    height: 100%;
} */

.team-img .time-img1 .timeImg1 img {
    width: 100%;
}

.team-img .time-img1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
}

.team-img .time-img1 .timeImg2 {
    width: 286px;
    height: 173px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 2px solid #04542C;
    background: rgba(177, 241, 199, 0.50);
}

@media screen and (max-width: 1200px) {
    .team-container {
        display: flex;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
    }

    .team-text h5 {
        width: 500px;
       font-size: 26px;
       
    }    
    
    .team-text p {
        width: 500px;
        font-size: 15px;        
    }
}

@media screen and (max-width: 1100px) {
    .team-container {
        display: flex;        
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 20px;
        padding: 30px;
    }
}


@media screen and (max-width: 650px) {
    .team-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;
        margin-top: 20px;
    }
    
    .team-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .team-text .icon-img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .team-text h5 {
        width: 350px;        
        font-size: 18px;
        text-align: center;       
    }

    .team-text p {
        margin-top: 10px;
        width: 350px;        
        font-size: 15px;
        text-align: center;       
    }

    .team-img {
        display: flex;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
    }

    .team-img img {
        width: 150px;
    }
    
    .team-img .time-img1 .timeImg1 img {
        width: 150px;
    }

    .team-img .time-img1 .timeImg2 {
        width: 150px;
        height: 90px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 2px solid #04542C;
        background: rgba(177, 241, 199, 0.50);
    }
    
}
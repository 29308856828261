.learn_more_hero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 40px;
    background: white;
}

.learn_more_hero h2{
    font-size: 52px;
}


.learn_more_hero p{
    text-align: center;
    font-size: 19px;

}
.box_parent{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 30px;
}

.box{
    width: 40%;
    min-width: 340px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin: 20px;
}

.box p{
  text-align: center;
  font-size: 23px;
}
.box .text_in_box{
  text-align: center;
  font-size: 18px;
}

@media screen and (min-width: 1100px) {
    .reverse_learnmore{
        flex-direction: row-reverse !important;
    }
    
  }


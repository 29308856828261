.job-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px;
    margin-top: 70px;
    flex-direction: row-reverse;
}

.job-text .icon-img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.job-text h5 {
    width: 600px;
    color: #73C31D;
    font-family: League Spartan;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: end;
}

.job-text .icon-img h6 {
    color: #000;
    font-family: League Spartan;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: end;
}

.job-text p {
    margin-top: 10px;
    width: 600px;
    color: #595959;
    font-family: League Spartan;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px; 
    text-align: end;
}

.job-text button {
      margin-top: 20px;
    border: none;
    color: #FFF;
    font-family: League Spartan;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* width: 150px; */
    padding: 2px 20px;
    /* height: 40px; */
    flex-shrink: 0;
    border-radius: 25px;
    background: #04542C;
    float: right;
    border: 2px solid #04542C;
}
.job-text button:hover{
        background: white;
        color:#04542C ;
}

/* time-img */
.job-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    column-gap: 10px;
    /* width: 550px; */
}
/* 
.time-img img {
    width: 100%;
    height: 100%;
} */

.job-img .time-img1 .timeImg1 img {
    width: 100%;
}

.job-img .time-img1 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}

.job-img .time-img1 .timeImg2 {
    width: 286px;
    height: 173px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 2px solid #04542C;
    background: rgba(177, 241, 199, 0.50);
}

/* arrow */
.job-img .time-img1 .timeImg2 img {
    margin-top: 250px;
    margin-left: 300px;
    width: 50%;
}

@media screen and (max-width: 1200px) {
    .job-container {
        display: flex;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
        padding: 30px;
    }

    .job-img .time-img1 .timeImg2 img {
       display: none;
    }


    .job-text h5 {
        width: 500px;        
        font-size: 26px;
        
    }
    
    
    .job-text p {
        width: 500px;
        font-size: 15px;        
    }
    
}


@media screen and (max-width: 1100px) {
    .job-container {
        display: flex;        
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 20px;
        padding: 30px;
    }
}

@media screen and (max-width: 650px) {
    .job-container {
        margin-top: 20px;
    }

   .job-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   }

    .job-text .icon-img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    

    .job-text h5 {
        width: 350px;        
        font-size: 18px;
        text-align: center;
       
    }

    .job-text p {
        margin-top: 10px;
        width: 350px;        
        font-size: 15px;
        text-align: center;       
    }

    .job-img {
        display: flex;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
    }
    
    .job-img img {
        width: 150px;
    }

    .job-img .time-img1 .timeImg1 img {
        width: 150px;
    }

    .job-img .time-img1 .timeImg2 {
        width: 150px;
        height: 90px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 2px solid #04542C;
        background: rgba(177, 241, 199, 0.50);
    }


}




.MuiTableContainer-root::-webkit-scrollbar {
    width: 1px;
}

input:focus-visible {
    outline: none;

}

input:focus {
    outline: none;
    border-bottom: 2px solid #04542C !important;
}

textarea:focus {
    outline: none;
    border-bottom: 2px solid #04542C !important;
}

.expense-edit .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 850px !important;
    height: 380px;
}

.expense-edit .css-uhb5lp {
    min-width: 850px !important;
    height: 380px;
}

.MuiTablePagination-root::-webkit-scrollbar {
    width: 1px;
}

.edit_textarea {
    border: 1px solid #c4c4c4 !important;
    border-radius: 5px;
    background-color: #fff;
    padding: 3px 10px !important;
    width: 100%;
    height: 40px;
}

.css-1ex1afd-MuiTableCell-root {
    text-align: center !important;
}

.css-q34dxg{
    text-align: center !important;
}

.MuiPaper-root {
    border-radius: 20px !important;
}

.deleteIcon:hover {
    color: red !important;
    transform: scale(1.1, 1.1);
    transition: 0.35s;
    cursor: pointer;
}

.userIconsHover:hover {
    /* color: red !important;  */
    transform: scale(1.2, 1.2);
    transition: 0.35s;
    cursor: pointer;
}

.table_overflow::-webkit-scrollbar {
    display: block !important;
    width: 10px;
    color: red;
}

@media screen and (max-width: 576px) {
    .expense-edit .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        min-width: 370px !important;
        height: 527px;
    }

    .expense{
        border-radius: 25px !important;
    }

    .form1{
        margin-bottom: 0 !important;
    }
}
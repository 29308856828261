.step_wizard_body{
    padding: 40px 100px;
}
.step_wizard_body h2{
    color: #04542c;
    font-size: 37px;
    margin-bottom: 15px;
}

.step_wizard_body .cards2{
    margin-top: 29px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.step_wizard_body .cards .step_card{
    width: 198px;
    height: 176px;
    border: 2px solid #2fff74;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.step_wizard_body .cards .step_card p{
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}

.step_wizard_body .cards .button{
margin-top: 50px;
}
.step_wizard_body .cards .button button{
    padding: 5px 30px;
    border: 2px solid #64e48e;
    border-radius: 11px;
    background: #64e48e;
    color: black;
    font-weight: 600;
    font-size: 22px;
}

.step_wizard_body .cards .button button:hover{
    background: white;
    color: #64e48e;
}
.chat_container{
    min-width: 600px !important;
    overflow-y: auto;
}
.chat_container .chat_box{
    display: flex;
    flex-direction: column;
    justify-content: end;
    min-height: 300px;
}

.chat_container form{
   display: flex;
   justify-content: space-between;
}
.chat_container form input{
   width: 70%;
   font-style: normal;
}

.chat_container form input:focus{
    border-bottom: 2px solid #04542C !important;
    outline: none !important; 
}

.chat_container form input:focus-visible{
    border-bottom: 2px solid #04542C !important;

}
  

.chat_container form div{
   display: flex;
   justify-content: space-between;
   width: 30%;
}

.chat_container form div button{ 
   padding: 0px 10px; 
   border-radius: 10px;
   font-style: normal;
}

.chat_container form div .close{ 
   border: 2px solid #04542c;
   background: white;
   color: #04542c;
}

.chat_container form div .send{ 
   border: 2px solid #04542c;
   background: #04542c;
   color: white;
   margin-left: 20px;
}

.chat_container p{
    padding: 3px 7px;
    background: #bbe7b3;
    border-radius: 10px 10px 10px 0px;
    max-width: 50%;
}

    /* 

.div1{
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    box-shadow: 0 0 20px #0000001a;
    background: #fff;
    animation: fade-in-down cubic-bezier(0,1,.47,1.01) .9s forwards;
}


.div2{
    position: relative;
    width: 64px;
    height: 64px;
    animation: fade-in-right cubic-bezier(0,1,.47,1.01) .8s forwards;
    animation-delay: .15s;
}
@keyframes another {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .another{
    animation: rotate 2s linear infinite;
    transform: rotate(-100deg);
  }
.div3{
    position: absolute;
    animation: rotate 2s linear infinite;
    width: 64px;
    height: 64px;
}
.div4{
    position: absolute;
    animation: rotating-logo 30s linear infinite;
    width: 64px;
    height: 64px;
}
.div5{
    position: absolute;
    width: 64px;
    height: 64px;
}
*/


/* div  */



/* Row 1 */
.company_settings_input{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.company_settings_input .fileSelect{
    padding: 6px 10px !important;
}
.user-tab-policy{
    margin: 10px 50px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #04542c;
    border-radius: 5px;
    padding: 11px;
    align-items: center;
}
.user-tab-policy p{
    font-weight: 500;
    margin: 0;
    font-size: 17px;
}
.adduser {
    background-color: #fff;
    margin-top: 22px;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 50px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.adduser-container {
    background: rgba(100, 228, 142, 0.80);
    height: 60px;
}

.adduser-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.adduser-top .adduser-header1 {
    margin-left: 10px;
}

.adduser-top .adduser-header1 h5{
    padding: 2px 40px;
    color: #000;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
}

.adduser-top .adduser-header2 img{
    padding: 0 10px;
    cursor: pointer;
}

.adduser-top .adduser-header2 img:hover{
    transform: scale(1.05, 1.05);
}

/* Row 2 */
.adduser-header {
    display: flex;
    justify-content: start;
    padding-top: 25px;
    margin-left:  30px;
    margin-right: 30px;
    margin-bottom: 30px;
    gap: 10px;
}

.adduser-header .btn1{
    border-radius: 10px;
    background: #64E48E;
    border: 1px solid #64E48E;
    width: 127px;
    height: 31px;
    font-weight: 500;
    cursor: pointer;
}

/* Form */
.adduser-form {
    display: flex;
    justify-content: space-evenly;
    /* padding-bottom: 30px; */
    /* margin-bottom: 50px; */
}

.adduser-form .form1 {
    margin: 10px 0;
} 

.adduser-form label {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 15px;
}

.adduser-form input {
    width: 240px;
    /* padding: 19px 0; */
    height: 40px;
    border-radius: 5px;
    
    border-color: gray;
    border: 1px solid gray;
    margin-left: 5px;
}

.adduser-form input:focus {
   border-bottom: 2px solid #04542C !important;
   outline: none;
}



.adduser-form input[name="name"] {
    margin-left: 30px;
}

.adduser-form input[name="email"] {
    margin-left: 33px;
}

.adduser-form input[name="phone"] {
    margin-left: 25px;
}

.add_user_input_margin_bottom{
    margin-bottom: 2.5rem;
}

.add_user_input_margin_bottom_for_dialog{
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
}

.adduser-form input[name="password"] {
    margin-left: 36px;
} 

/* .adduser-form input[name="role"] {
    margin-left: 56px;
} */

.role-border {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.role {
    width: 240px;
    margin-left: 66px;
    border: 1px solid darkgray;
    height: 40px;
}

.form2{
    margin: 8px 0px;
}

.role:focus {
    box-shadow: none;
    border-bottom: 2px solid #04542C !important;
}


.adduserBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
}


.adduser .btn5 {
    width: 134px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #64E48E;
    border: none;
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 40px;
}

.admin {
    margin-bottom: 50px;
    margin-top: 50px;
}

.admin .admin-btn {
    border: none;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 127px;
    height: 31px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #64E48E;
    cursor: context-menu;
}

.admin-container {
    margin-left: 50px;
    margin-right: 50px;
}


@media screen and (max-width: 1000px) {
    .adduser-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 15px;
    }  

    .adduser-form input[name="password"] {
        margin-left: 8px;
    } 
    
    /* .adduser-form input[name="role"] {
        margin-left: 40px;
    } */

    .role {
        width: 240px;
        margin-left: 40px;
        border: 1px solid darkgray;
    }
    
}

@media screen and (max-width: 576px) {
    .adduser-form input {
        width: 200px;
    }

    .adduser {
       
        margin-left: 5px;
        margin-right: 5px;
    }
    
    .admin-container {
        margin-left: 5px;
        margin-right: 5px;
    }

    .adduserBtn {
        padding-left: 50px;    
    }

    /* .adduser-form .form2 {
        margin-left: 10px;
    } */

    .adduser-form input[name="password"] {
        /* margin-left: 20px; */
        width: 200px;
    }

    .role {
        width: 200px;
        margin-left: 40px;
        border: 1px solid darkgray;
    }
    
    
}
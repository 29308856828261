.assign-input
{
    display : flex ;
    width : 100% ;
    gap : 59px ;
    flex-direction: row;
   align-items: center;

}

@media(max-width:1000px){
    .assign-input
{
    flex-direction: column;
    width: fit-content;
    gap: 0px;  
}

}

@media(max-width:700px)
{
    .radio-group{
        margin-left:35px !important;
        width: 100%;
    }

    .radio-group 
    {
       height: 100%;
    }
}

.active {
  overflow-y: auto;

}
.active-tab-in-side{
  background: #73c31d63;
}
.logo_img {
  width: 50%;
}
.side_bar_icons{
  width: 24px;
}
.hoc_text {
  font-weight: 400 !important;
}

.leftdash1 {
  overflow-y: auto;
}
.dropdown-5{
  margin-left: 8px;
}

.active::-webkit-scrollbar {
  display: none;
}

.leftdash1::-webkit-scrollbar {
  display: none;
}

.hamburgar {
  color: #000;
  font-size: 25px;
  position: absolute;
  top: 13px;
  display: none;
  margin-left: 20px;
}

.leftdash1 {
  display: none;
}

.active {
  display: block;
}

.hamburgar {
  display: block;
}

.css-k42nt0-MuiListItem-root:hover {
  background-color: white;
}

.css-k42nt0-MuiListItem-root:hover .css-4dvnoe-MuiTypography-root {
  color: #04542C;
}



/* column left */
.leftdash1 {
  background-color: #04542C;
  max-height: 100%;
  padding: 0;

}

.flex-head {
  display: flex;
  flex-direction: column;
}

.hamburgar {
  color: #000;
  font-size: 25px;
  position: absolute;
  top: 13px;
  /* display: none; */
}


.logo {
  text-align: center;
  margin: 30px 50px;
}

.logo h1 {
  background-color: #fff;
  border-radius: 50px;
  font-size: 25px;
  padding: 5px 0;
  /* font-family: 'Inter' sans-serif;  */
}

.dashmenu1 .menu1 p {
  padding: 10px 10px;
  margin-bottom: 0px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  font-family: 'Inter';
}

.dashmenu1 .menu1 p:hover {
  color: #04542C;
  background-color: #fff;
}

.dashmenu1 .menu1 p i {
  float: right;
}
.hoc_parent:hover .hoc_text{
     color: rgba(100, 228, 142, 0.80);
}

.hoc_parent1:hover{
  color: rgba(100, 228, 142, 0.80);
}
.hoc_parent2:hover{
  color: rgba(100, 228, 142, 0.80);
}

.leftdash1 {
  display: none;
}

.leftdash1.active {
  display: block;
}

.hamburgar {
  display: block;
}


@media screen and (max-width: 840px) {
  .logo_img {
    width: 104px;
  }
}

@media screen and (max-width: 1050px) {
  /* .leftdash1 {
        display: none;
    }
     
     .leftdash1.active {
         display: block;
    }
    
    .hamburgar {
        display: block;
    } */
}


@import url('https://fonts.googleapis.com/css?family=Sen&display=swap');

.li_items {
  align-items: center;
}

.dropdown {

  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  cursor: pointer;
  position: relative;
  perspective: 1000px;
  z-index: 100;
}

.user-dropdown {

  color: white;
  font-size: 18px;
  /* font-weight: 600; */
  cursor: pointer;
  position: relative;
  perspective: 1000px;
  z-index: 100;
}

.dropdown:hover {
  cursor: pointer;
}

.user-dropdown:hover {
  cursor: pointer;
}

.dropdown:hover .dropdown_menu li {
  display: flex;
}

/* .user-dropdown:hover .user-dropdown_menu li {
  display: flex;
}

.userHoverActive:hover {
  margin-bottom: 113px; 
} */

.dropdown_menu {
  position: absolute;
  top: 130%;
  left: 0;
  /* width: 166%; */
  /* perspective: 1000px; */
  z-index: -1;
  padding: 0;
  width: 180px;
}

.user-dropdown_menu {
  position: absolute;
  top: 100%;
  left: 16%;
  /* width: 166%; */
  /* perspective: 1000px; */
  z-index: -1;
  padding: 0;
  width: 200px;
}

.dropdown_menu li {
  display: none;
  color: white;
  padding: 10px 20px;
  padding-left: 0px;
  font-size: 15px;
  opacity: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.user-dropdown_menu li {
  display: none;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  opacity: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.dropdown_menu li:hover {}

.dropdown:hover .dropdown_menu--animated {
  display: block;
}

.dropdown_menu--animated {
  display: none;
}

.dropdown_menu--animated li {
  display: block;
  opacity: 1;
}

.dropdown_menu-6 {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

.dropdown_menu-7 {
  animation: rotateMenu 400ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(20deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.dropdown_menu-8 {
  animation: downOut 300ms ease-in-out forwards;
  transform-origin: center center;
}

@keyframes downOut {
  0% {
    transform: translateZ(200px) translateY(40px);
  }

  80% {
    transform: translateZ(-10px) translateY(0px);
  }

  100% {
    transform: translateZ(0px) translateY(0px);
  }
}

.dropdown_menu-9 {
  animation: growOut 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growOut {
  0% {
    transform: scale(0);
  }

  80% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.dropdown_menu-10 {
  animation: rotateY 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes rotateY {
  0% {
    transform: rotateY(90deg);
  }

  80% {
    transform: rotateY(-10deg);
  }

  100% {
    transform: rotateY(0);
  }
}




.dropdown_menu-5 .dropdown_item-1 {
  transform-origin: top center;
  animation: translateX 300ms 60ms ease-in-out forwards;
}

.dropdown_menu-5 .dropdown_item-2 {
  transform-origin: top center;
  animation: translateX 300ms 120ms ease-in-out forwards;
}

.dropdown_menu-5 .dropdown_item-3 {
  transform-origin: top center;
  animation: translateX 300ms 180ms ease-in-out forwards;
}

.dropdown_menu-5 .dropdown_item-4 {
  transform-origin: top center;
  animation: translateX 300ms 240ms ease-in-out forwards;
}

.dropdown_menu-5 .dropdown_item-5 {
  transform-origin: top center;
  animation: translateX 300ms 300ms ease-in-out forwards;
}


@keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }

  80% {
    transform: translateX(-5px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
.who_is_working{
    padding: 20px;
}

.left_side_employee{
    background: white;
    min-height: 85vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}
.who_is_working .left_side_employee_col{
    padding-right: 10px !important;
}

.who_is_working .map{
    background: white;
    min-height: 85vh;
    border-radius: 15px;
    padding: 10px;
}
/* aboutPageContainer */
.faqPageContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-image: url(../img/HeroImg.png);
    padding-top: 100px;
    margin-top: -100px;
    min-height: 95vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-left: 15px;
    padding-right: 15px;
    gap: 10px;
    margin-bottom: 100px;
  }
  
  .faqPageLeft {
    padding-bottom: 20px;
  }
  
  
  .faqPageLeft h1 {
    color: #04542c;
    max-width: 600px;
    font-family: League Spartan;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .faqPageLeft p {
    max-width: 600px;
    margin-top: 30px;
    color: #000;
    font-family: League Spartan;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  
  .faqPageLeft button {
    margin-top: 30px;
    width: 200px;
    height: 50px;
    flex-shrink: 0;
    border: none;
    color: #fff;
    text-align: center;
    font-family: League Spartan;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 30px;
    background: #04542c;
    transition: transform 0.3s;
  }
  
  .faqPageLeft button:hover {
    border: 2px solid #04542c;
    background: white;
    color: #04542c;
  }
  
  /* aboutPageRight */
  .faqPageRight {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .faqPageRight img {
    width: 80%;
    border-radius: 35px;
    height: 350px;
  }


  /* tablet and mobile view */

@media screen and (max-width: 1000px) {
    .faqPageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 50px;
      padding-top: 150px;
      padding-bottom: 50px;
    }

  }
  
  
  @media screen and (max-width: 600px) {
    .faqPageContainer {
      margin-bottom: 70px;
    }
  
    .faqPageLeft {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
  
    .faqPageLeft h1 {
      font-size: 30px;
      margin-top: 5px;
    }
  
    .faqPageLeft p {
      text-align: justify;
    }
  
    .faqPageRight img {
      width: 100%;
      height: auto;
      border-radius: 25px;
    }


  }
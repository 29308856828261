@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

* {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "DM Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
  font-family: 'League Spartan', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto Condensed', sans-serif;
}

code {
  font-family: "DM Sans", sans-serif !important;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
  /* monospace; */
}

.fa,
[class^="fa-"],
[class*=" fa-"] {
  font-family: "FontAwesome" !important;
}

tspan {
  font-size: 12px;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  margin-bottom: 10px !important;
}

.row {
  margin-right: 0px !important;
}

.row>* {
  padding-right: 0px !important;
}

.profile-header2 {
  display: flex;
}

.profile-header2 img {
  padding: 0px 10px;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.css-pwxzbm {
  box-shadow: none !important;
  border: 1px solid #ababab;
}

.css-rorn0c-MuiTableContainer-root {
  overflow: visible !important;
}

.css-kge0eu {
  overflow: visible !important;
}

.profile_typo {
  width: 100%;
  padding: 2px 4px;
  border-radius: 5px;
  text-align: start !important;
  cursor: pointer;
}

.profile_typo:hover {
  background: rgb(218, 218, 218);

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.css-dwuj3p-MuiTableCell-root,
.css-y8ay40-MuiTableCell-root {
  font-size: 0.900rem !important;
  font-weight: 600 !important;
}

label {
  margin-bottom: 5px;
}

textarea {
  padding: 5px;
}

g text {
  font-size: 14px;
}

label {
  display: flex !important;
}

.add_user_page_form label {
  display: inline-block !important;
  position: relative;
}

.add_user_page_form label i{
  position: absolute;
  top: 0px;
}

label i {
  font-size: 7px !important;
  color: red !important;
  margin-bottom: 9px;
}
.MuiTableContainer-root::-webkit-scrollbar {
    width: 1px;
}

.MuiTablePagination-root::-webkit-scrollbar {
    width: 1px;
}

.reject_reson {
    display: flex;
    padding: 10px 27px;
    gap: "10px";
    background: "white";
    align-items: "center";
}

.expense_card{
    width: 190px !important;
    padding: 0px 10px;
}

.expense_card_sheet {
    width: 228px !important;
    border-radius: 5px !important;
    
}

.active_tab{
    background-color: rgba(100, 228, 142, 0.80) !important;
}

.expense_card_sheet:hover {
    background-color: rgba(100, 228, 142, 0.80) !important;
}

.reject_reson textarea {
    width: 100%;
    resize: none;
    border-radius: 5px;
}

.reject_reson textarea:focus {
    outline: none;
    border-bottom: 2px solid #04542C !important;
}

.reject_reson button {
    border: none;
    width: 120px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    background-color: rgb(100, 228, 142);
    cursor: pointer;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}


.css-1ex1afd-MuiTableCell-root {
    text-align: center !important;
}

.css-q34dxg{
    text-align: center !important;
}

.MuiPaper-root {
    border-radius: 20px !important;
}

/* .MuiTableCell-root  {
    color: red;
} */
.submit_data_filter {
    cursor: pointer;
    max-width: 200px;
    margin-top: 30px !important;
    margin-bottom: 50px;
}

.aproveFilterBy_parent {
    display: flex;
    justify-content: space-between;

}

.aproveFilterBy_parent .text-time{
    display: flex;
    align-items: center;
}

.aproveFilterBy_parent .text-time .input-menu-focus{
    width: 110px;
}

.aproveFilterBy_parent .input-menu{
    display: flex;
    gap: 10px;
}

.button-container{
    align-items: center;
}

.aproveFilterBy_parent .button-container .sendToQBButton {
    width: 174px;
    background: #80e6a2;
    color: black;
}

.aproveFilterBy_parent .button-container .sendToQBButton:hover {
    background: white;
    color: black;
}

.adminlayout {
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 50px;
    background: #F1F1F1;
    padding-top: 30px;
    min-height: 100vh;
}

/* timesheet container */

.mainContainer {
    background-color: #fff;
    border-radius: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
}

/* Admin */

.admin h1 {
    float: left;
    flex-shrink: 0;
    padding: 9px 50px;
    border-radius: 10px;
    color: #000;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #64E48E;
    margin-bottom: 50px;
    cursor: context-menu;
}

.manager h1 {
    float: left;
    flex-shrink: 0;
    padding: 9px 50px;
    border-radius: 10px;
    color: #000;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #64E48E;
    margin-bottom: 50px;
    cursor: context-menu;
}

.user h1 {
    float: left;
    flex-shrink: 0;
    padding: 9px 50px;
    border-radius: 10px;
    color: #000;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #64E48E;
    margin-bottom: 50px;
    margin-top: 50px;
    cursor: context-menu;
}


/* Row 2 */
/* .expense-calender {
    margin-top: 50px;
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 50px;
    width: 1098px;
    height: 880px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
} */


.calender1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calender1 .time-div1 {
    display: flex;
    padding-top: 25px;
}

.calender1 .time-div1 .time1 {
    background: #73C31D;
    padding: 10px 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #fff;
    font-size: 20px;
}

.calender1 .time-div1 .time3 {
    background: #73C31D;
    padding: 10px 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    font-size: 20px;
}

.calender1 .time-div1 .middle-text-bill {
    background: #FFF;
    height: 40px;
    width: 270px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    padding-top: 10px;
    padding-left: 60px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.calender1 .time-div1 .calender {
    padding-right: 40px;
    background-color: #fff;
    height: 42px;
    border-top: 2px solid #ECEBEB;
    border-bottom: 2px solid #ECEBEB;
}

.calender1 .time-div1 .calender i {
    color: #73C31D;
    font-size: 28px;
    margin-top: 5px;
}

.calender1 .time-div1 .middle-text span {
    color: #000;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* padding: 0 30px; */
}

.time-div1 .middle-text p {
    margin-top: -10px;
}

.time-div2 {
    width: 280px;
    border-radius: 10px;
    background: #FFF;
    margin-top: 30px;
}

.time-div2 .drop-div {
    padding: 5px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
}


/* list */
.list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ECEBEB;
    margin-top: 20px;
    border-radius: 20px;
}

.list .list1 {
    display: flex;
    align-items: center;
    padding-top: 10px;
}

.list .list1 p {
    margin-left: 10px;
    background-color: #fff;
    padding: 10px;
    font-size: 12px;
    border-radius: 10px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}

.list .list1 .para1 {
    background-color: #04542C;
    color: #fff;
}

.list .searchbar2 {
    width: 200px;
    height: 39px;
    flex-shrink: 0;
    border: 1px solid #04542C;
    background: #FFF;
    margin-right: 15px;
    display: flex;
    border-radius: 10px;
    /* align-items: center; */
}

.list .searchbar2 p {
    color: #000;
    padding-top: 8px;
    padding-left: 10px;
    vertical-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.list .searchbar2 svg {
    margin-top: 5px;
    margin-left: 100px;
}

.select-all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 30px;
}

.form-expense {
    display: flex;
}

.form-expense p {
    margin-top: -10px;
}


.form-expense .para1 {
    border-radius: 10px;
    background: #C1C1C1;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 30px;
    margin-left: 10px;
    display: flex;
    align-items: center;

}

.form-expense .para2 {
    border-radius: 10px;
    border: 1px solid #C1C1C1;
    color: #000;
    background-color: none;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 20px;
    margin-left: 10px;
}

.form-expense input[type="checkbox"] {
    width: 15px;
    /* margin-top: 10px; */
    margin-left: 10px;
}


.group strong {
    font-size: 12px;
    font-family: 'Inter', sans-serif;
}

.group button {
    border-radius: 10px;
    border: 1px solid #30B05A;
    background: #FFF;
    margin-left: 10px;
    padding: 5px 20px;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
}
.all_approve_button{
    margin: 30px 0px;
}

@media screen and (max-width: 1200px) {
    .list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 1200px) {
    .all_approve_button{
        margin-top: 30px;
    }
}

@media screen and (max-width: 900px) {

    .adminlayout {
        padding-right: 10px;
        padding-left: 10px;
    }

    .mainContainer {
        border-radius: 40px;
        padding-left: 5px;
        padding-right: 5px;

    }

    .list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .list .list1 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .select-all {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .searchbar2 {
        margin-bottom: 20px;
    }

    .calender1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .time-div2 {
        margin-right: 0px;
    }

}

@media screen and (max-width: 700px) {
    .expense-calender {
        margin-right: 5px;
        margin-left: 5px;
    }
}

@media screen and (max-width: 576px) {
    .list {
        margin-left: 5px;
        margin-right: 5px;
    }

    .list .list1 p {
        margin-left: 5px;
        padding: 5px 10px;
        font-size: 12px;
        border-radius: 10px;
        font-weight: 400;
    }

    .calender1 .time-div1 .middle-text-bill {
        font-weight: 500;
        width: 170px;
        padding-left: 20px;
    }

    .group {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .group button {
        margin-left: 10px;
        padding: 5px 10px;
    }

    .calender1 .time-div1 .calender {
        padding-right: 15px;

    }

    .calender1 .time-div1 .calender i {

        font-size: 28px;

    }
}